import React, { useEffect, useState } from 'react';
import { apiGet, apiPost } from '../../../api';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { ButtonGroup, CardHeader, useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import errorHandler from '../errorHandler';
import toast from 'react-hot-toast';
import ConfirmDialog from '../../common/dialogs/confirm';
import { setShowingDialog } from '../../../store/app';
import { useDispatch, useSelector } from 'react-redux';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Tooltip from '@mui/material/Tooltip';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {
  editBalance,
  editBalanceWithoutSymbol,
} from '../../../helpers/BalanceHelper';
import { CustomCell } from '../../common/tables/CustomCell';
import InvoiceForPaymentDialog from '../../common/dialogs/CreateAnInvoiceForPayment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ProductAdditionalAgreement = ({ onRefetch }) => {
  const open = useSelector((state) => state.layout.open);

  const productModel = useSelector((state) => state.products.productInfo);

  const additionalAgreements = productModel?.rentalContracts || [];

  const [rentalProductId, setRentalProductId] = useState('');

  // Dialog Подтверждение закрытия доп соглашения
  const [closeAddId, setCloseAddId] = useState('');
  const [isOpenCloseAdditionalDialog, setIsOpenCloseAdditionalDialog] =
    useState(false);

  const [
    showChooseDocsForDownloadingDialog,
    setShowChooseDocsForDownloadingDialog,
  ] = useState(false);

  const [additionalDocId, setAdditionalDocId] = useState('');

  const [userAccess, setUserAccess] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getInvoiceList = () => {
    apiGet({
      url: '/company-accounts/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setInvoiceList(res.data.models);
    });
  };

  const deleteRentalContract = (id) => {
    dispatch(setShowingDialog('deleteRentalContract'));
    setRentalProductId(id);
  };

  const confirmDeleteRentalContract = () => {
    apiGet({
      url: `/rental-product/delete?id=${rentalProductId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Удалено');

      // Все доп соглашения
      onRefetch?.();
    });
  };

  const closeDialog = () => {
    setIsOpenCloseAdditionalDialog(false);
    setShowChooseDocsForDownloadingDialog(false);
    setAdditionalDocId('');
  };

  const closeRentalContract = () => {
    apiGet({
      url: `/rental-product/close?id=${closeAddId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      // Все доп соглашения
      onRefetch?.();
      toast.success('Доп соглашение закрыто');
    });
    closeDialog();
  };

  const openAdditionalContractPage = (id) => {
    navigate(`/additional-agreement/${id}`);
  };

  const chooseDocsAndIdForDownLoadingHandler = (id) => {
    setShowChooseDocsForDownloadingDialog(true);
    setAdditionalDocId(id);
  };

  const anAcceptanceCertificate = (downloadItem) => {
    let url = '';

    if (downloadItem === 'anAcceptanceCertificate')
      url = '/legal-document/an-acceptance-certificate';

    if (downloadItem === 'additionalAgreements')
      url = '/legal-document/additional-agreements';

    if (downloadItem === 'transferAcceptanceAct')
      url = '/legal-document/transfer-acceptance-act';

    if (downloadItem === 'contract') url = '/legal-document/contract';

    apiGet({
      url: `${url}?type=1&id=${additionalDocId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      window.open(`${res.data.link}`);
      toast.success('Скачано');
      // closeDialog()
    });
  };

  function getCellStyle(isEnded) {
    const sxProps = {
      cursor: 'pointer',
      py: 0.5,
    };
    if (isEnded) {
      sxProps.backgroundColor = '#d3a9a9';
    }

    return sxProps;
  }

  const getTotalRentSum = () => {
    return additionalAgreements?.reduce((acc, item) => {
      acc += item?.rentSum || 0;

      return acc;
    }, 0);
  };

  const getTotalBalance = () => {
    return additionalAgreements?.reduce((acc, item) => {
      acc += item?.balanceClient || 0;

      return acc;
    }, 0);
  };

  const getTotalDeliveryReturnSum = () => {
    return additionalAgreements?.reduce((acc, item) => {
      acc += item?.delivery_price || 0;
      acc += item?.return_price || 0;

      return acc;
    }, 0);
  };

  useEffect(() => {
    const userRoleAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userRoleAccessLocal);

    getInvoiceList();
  }, []);

  return (
    <Card
      className={!open ? 'Overflow-box-each-show-menu' : 'Overflow-box-each'}
      sx={{ overflowX: 'auto', my: 2 }}
      raised
    >
      {!additionalAgreements?.length ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
          }}
        >
          <CardHeader title={'Доп соглашения не найдены'} />
        </Box>
      ) : (
        <>
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      rowSpan='2'
                      align='center'
                    >
                      Номер соглашения
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      rowSpan='2'
                      align='center'
                    >
                      Имя клиента
                    </StyledTableCell>

                    <StyledTableCell
                      rowSpan='2'
                      align='center'
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                    >
                      Товар
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      colspan='2'
                      align='center'
                    >
                      Дата
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      colspan='3'
                      align='center'
                    >
                      Оплаты
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      rowSpan='2'
                      align='center'
                    >
                      Дата закрытия
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      rowSpan='2'
                      align='center'
                    >
                      Дата возврата
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      rowSpan='2'
                      align='center'
                    >
                      Действия
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      align='center'
                    >
                      От
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      align='center'
                    >
                      До
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      align='center'
                    >
                      Доставка/Возврат
                      <br />{' '}
                      <span className='whitespace-nowrap text-inherit'>
                        {editBalance(getTotalDeliveryReturnSum(), true)}
                      </span>
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                      }}
                      align='center'
                    >
                      Аренда <br />{' '}
                      <span className='whitespace-nowrap text-inherit'>
                        {editBalance(getTotalRentSum(), true)}
                      </span>
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                        borderRight: '1px solid #e0e0e0',
                      }}
                      align='center'
                    >
                      Баланс <br />{' '}
                      <span className='whitespace-nowrap text-inherit'>
                        {editBalance(getTotalBalance(), true)}
                      </span>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{ whiteSpace: 'nowrap' }}>
                  {additionalAgreements.map((item) => (
                    <TableRow key={item?.id}>
                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {item.id}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        align='center'
                      >
                        {item?.client?.fullName}
                      </CustomCell>

                      <CustomCell
                        align='center'
                        sx={getCellStyle(item?.end)}
                      >
                        {productModel.name}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {item?.start || '-'}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {item?.end || '-'}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {editBalance(item?.delivery_price) || 0} /{' '}
                        {editBalance(item?.return_price) || 0}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {editBalance(item?.rentSum)}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {editBalance(item?.balanceClient)}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {item?.end || '-'}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        onClick={() => openAdditionalContractPage(item.id)}
                        align='center'
                      >
                        {item?.returnDate || '-'}
                      </CustomCell>

                      <CustomCell
                        sx={getCellStyle(item?.end)}
                        align='center'
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          {item?.end === null ? (
                            <>
                              {userAccess['legal-document']?.[
                                'an-acceptance-certificate'
                              ] ? (
                                <Tooltip
                                  title={'Выбрать документы для скачивания'}
                                >
                                  <IconButton
                                    sx={{
                                      backgroundColor: '#e0e0e0',
                                      mr: 1,
                                      '&:hover': { backgroundColor: '#c2c2c2' },
                                    }}
                                    color={'success'}
                                    component='span'
                                    onClick={() =>
                                      chooseDocsAndIdForDownLoadingHandler(
                                        item.id,
                                      )
                                    }
                                  >
                                    <FilePresentIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <></>
                              )}

                              {userAccess['payment-transaction']?.create && (
                                <InvoiceForPaymentDialog
                                  onRefetch={onRefetch}
                                  rentalProductId={item.id}
                                  invoiceList={invoiceList}
                                />
                              )}

                              {userAccess['rental-product']?.close && (
                                <>
                                  <Tooltip title={'Закрыть доп соглашение'}>
                                    <IconButton
                                      setIsOpenCloseAdditionalDialog
                                      sx={{
                                        backgroundColor: '#e0e0e0',
                                        mr: 1,
                                        '&:hover': {
                                          backgroundColor: '#c2c2c2',
                                        },
                                      }}
                                      onClick={() => {
                                        setCloseAddId(item.id);
                                        setIsOpenCloseAdditionalDialog(true);
                                      }}
                                      color='abortButton'
                                    >
                                      <DoDisturbIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {userAccess['rental-product']?.delete ? (
                            <Tooltip title={'Удалить доп соглашение'}>
                              <IconButton
                                sx={{
                                  backgroundColor: '#e0e0e0',
                                  mr: 1,
                                  '&:hover': { backgroundColor: '#c2c2c2' },
                                }}
                                color='warning'
                                component='span'
                                onClick={() => deleteRentalContract(item.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </CustomCell>
                    </TableRow>
                  ))}
                </TableBody>

                {additionalAgreements?.length && (
                  <TableRow>
                    <StyledTableCell
                      align='center'
                      colSpan={1}
                      sx={{
                        borderRight: '1px solid #e0e0e0',
                        fontWeight: '600',
                        fontSize: '18px',
                      }}
                    >
                      {additionalAgreements?.length}
                    </StyledTableCell>

                    <StyledTableCell colSpan={1}></StyledTableCell>

                    <StyledTableCell colSpan={1}></StyledTableCell>

                    <StyledTableCell colSpan={1}></StyledTableCell>
                    <StyledTableCell colSpan={1}></StyledTableCell>

                    <StyledTableCell
                      sx={{
                        borderLeft: '1px solid #e0e0e0',
                        borderRight: '1px solid #e0e0e0',
                        fontWeight: '600',
                        fontSize: '18px',
                      }}
                      colSpan={3}
                      align='center'
                    >
                      {editBalanceWithoutSymbol(
                        getTotalRentSum() +
                          getTotalBalance() +
                          getTotalDeliveryReturnSum(),
                      )}{' '}
                      руб
                    </StyledTableCell>

                    <StyledTableCell colSpan={1}></StyledTableCell>
                    <StyledTableCell colSpan={1}></StyledTableCell>
                    <StyledTableCell colSpan={1}></StyledTableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      <ConfirmDialog
        title={'Удалить доп соглашение?'}
        buttonTitle={'Удалить'}
        event={confirmDeleteRentalContract}
        name={'deleteRentalContract'}
      />

      <Dialog
        fullWidth
        open={isOpenCloseAdditionalDialog}
        maxWidth='sm'
        scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 2,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Закрыть доп соглашение?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='abortButton'
            sx={{ marginRight: 1 }}
            onClick={closeRentalContract}
          >
            Закрыть соглашение
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>

      {/*Start ----------Выбрать документы для скачивания*/}
      <Dialog
        fullWidth
        open={showChooseDocsForDownloadingDialog}
        maxWidth='md'
        scroll='body'
        onClose={closeDialog}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Выбрать документы для скачивания
            </Typography>
          </Box>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <ButtonGroup
              fullWidth
              size={'large'}
              orientation='vertical'
              aria-label='vertical contained button group'
              variant='contained'
            >
              <Button
                sx={{ marginBottom: 1 }}
                onClick={() => anAcceptanceCertificate('contract')}
              >
                Сгенерировать договор{' '}
              </Button>

              <Button
                onClick={() => anAcceptanceCertificate('transferAcceptanceAct')}
              >
                Сгенерировать акт приема-передачи
              </Button>
              <Button
                sx={{ my: 1 }}
                onClick={() => anAcceptanceCertificate('additionalAgreements')}
              >
                Сгенерировать заявку на доп. соглашение
              </Button>
              <Button
                onClick={() =>
                  anAcceptanceCertificate('anAcceptanceCertificate')
                }
              >
                Сгенерировать акт приема-сдачи
              </Button>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='secondary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/*End ----------Выбрать документы для скачивания*/}
    </Card>
  );
};

export default ProductAdditionalAgreement;
